import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from '../DataStorage';
import { TypoController } from '../controller/TypoController';
import { SwipeController } from "../controller/SwipeController";
import { BookSliderController } from '../controller/BookSliderController';
import { gsap, Power1 } from "gsap";

export class EpisodeView
{
	private element: HTMLElement;
	
	private typo: TypoController[];
	private slider: BookSliderController;

	private addBindThis: Function;
	private hideBindThis: Function;
	private showBindThis: Function;
	private removeBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#episode");

		new SwipeController(this.element.querySelector("._swipe"));
		this.slider = new BookSliderController(this.element.querySelector("._slider"));

		this.element.querySelector('._backToTop').addEventListener('click', (event) =>
		{
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_EPISODE));
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BOOK_CLOSE));
		});

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_EPISODE, this.addBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.GET_EPISODE_BY_EPISODE_ID_COMPLETE, this.addBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.BOOK_OPEN_COMPLETE, this.addBindThis);

		this.hideBindThis = this.hide.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.PAGE_NEXT, this.hideBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.PAGE_PREV, this.hideBindThis);

		this.showBindThis = this.show.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.BOOK_NEXT_COMPLETE, this.showBindThis);
		SceneEvent.getInstance().addEventListener(SceneEvent.BOOK_PREV_COMPLETE, this.showBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_EPISODE, this.removeBindThis);

	}
	public add(): void
	{
		if(!(DataStorage.hasEpisode && DataStorage.isBookOpen)) return;

		this.element.querySelector("._no").innerHTML = "No."+DataStorage.episodeNo;

		gsap.set(this.element, {css:{display:"block"}});

		let _text = DataStorage.usersstoryEpisode.episode.replace(/\r?\n/g, '<br>').split('<br>');
		let _hoge = "";
		this.typo = [];
		for(let i=0; i<_text.length; i++)
		{
			for(let j=0; j<_text[i].length; j++)
			{
				this.element.querySelector("._text").innerHTML = _hoge+_text[i][j];
				if(this.element.querySelector("._text").clientHeight>DataStorage.window_w/750*550)
				{
					this.typo.push(new TypoController(this.element.querySelector("._text"), _hoge, i));

					_hoge = _text[i][j];
				}
				else
				{
					_hoge += _text[i][j];
				}
			}
			_hoge += "<br>";
		}
		this.typo.push(new TypoController(this.element.querySelector("._text"), _hoge, _text.length));

		this.element.querySelector("._text").innerHTML = "";
		
		for(let i=0; i<this.typo.length; i++)
		{
			this.typo[i].add();
		}

		console.log(this.typo)

		DataStorage.pageNumMax = this.typo.length;
		DataStorage.pageNum = 0;

		if(DataStorage.pageNumMax>1)
		{
			this.slider.add();
		}

		this.show();

	}
	public show(): void
	{
		for(let i=0; i<this.typo.length; i++)
		{
			this.typo[i].hide();
		}

		gsap.killTweensOf(this.element.querySelector("._title"));
		gsap.killTweensOf(this.element.querySelector("._no"));
		gsap.killTweensOf(this.element.querySelector("._backToTop"));
		gsap.killTweensOf(this.element);

		gsap.set(this.element.querySelector("._title"), {css:{display:"none", opacity:0}});
		gsap.set(this.element.querySelector("._no"), {css:{display:"none", opacity:0}});
		gsap.set(this.element.querySelector("._backToTop"), {css:{display:"none", opacity:0}});
		gsap.set(this.element.querySelector("._container"), {css:{opacity:1, display:"block"}});
		gsap.set(this.element, {css:{opacity:1, display:"block"}});

		gsap.to(this.element.querySelector("._title"), 0.6, {css:{display:"block", opacity:1}, ease:Power1.easeInOut});
		gsap.to(this.element.querySelector("._no"), 0.6, {css:{display:"block", opacity:1}, ease:Power1.easeInOut});
		gsap.to(this.element.querySelector("._backToTop"), 0.6, {css:{display:"block", opacity:1}, ease:Power1.easeInOut, delay:0.6});

		this.typo[DataStorage.pageNum].typo();

	}
	public hide(): void
	{
		gsap.to(this.element.querySelector("._container"), 0.2, {css:{opacity:0, display:"none"}, ease:Power1.easeInOut});

	}
	public remove(): void
	{
		gsap.to(this.element, 0.6, {css:{opacity:0, display:"none"}, ease:Power1.easeInOut});

		if(DataStorage.pageNumMax>1)
		{
			this.slider.remove();
		}
	}
}