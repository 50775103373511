import { Event } from "../events/EventDispatcher";
import { DataStorage } from "../DataStorage";
import { SceneEvent } from "../SceneEvent";
import { gsap, Power1 } from "gsap";

export class BookView
{
	private element: HTMLElement;

	private image_open: HTMLElement[] = [];
	private image_turnOver: HTMLElement[] = [];

	private num: number;

	private addBindThis: Function;
	private nextBindThis: Function;
	private prevBindThis: Function;
	private closeBindThis: Function;
	private removeBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#book");

		this.element.querySelectorAll('._open img').forEach((element) =>
		{
			this.image_open.push(element as HTMLElement);
		});
		this.element.querySelectorAll('._turnOver img').forEach((element) =>
		{
			this.image_turnOver.push(element as HTMLElement);
		});

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_BOOK, this.addBindThis);

		this.nextBindThis = this.next.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.PAGE_NEXT, this.nextBindThis);

		this.prevBindThis = this.prev.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.PAGE_PREV, this.prevBindThis);

		this.closeBindThis = this.close.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.BOOK_CLOSE, this.closeBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_BOOK, this.removeBindThis);
		
	}
	public add(): void
	{
		gsap.to(this.element, 0.6, {css:{display:"block", opacity:1}, ease:Power1.easeInOut});

		this.open();

	}
	public open(): void
	{
		DataStorage.isBookOpen = false;

		gsap.set(this.element.querySelectorAll('._open'), {css:{display:"block"}});
		gsap.set(this.element.querySelectorAll('._turnOver'), {css:{display:"none"}});

		this.num = 0;
		this.openAnimation();

	}
	public openAnimation(): void
	{
		for(let i=0; i<this.image_open.length; i++)
		{
			gsap.set(this.image_open[i], {css:{display:"none"}});
		}
		gsap.set(this.image_open[this.num], {css:{display:"block"}});

		if(this.image_open.length-1>this.num)
		{
			this.num++;

			setTimeout(this.openAnimation.bind(this), 30);
		}
		if(this.num==55)
		{
			DataStorage.isBookOpen = true;
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BOOK_OPEN_COMPLETE));
		}
	}
	public next(): void
	{
		gsap.set(this.element.querySelectorAll('._open'), {css:{display:"none"}});
		gsap.set(this.element.querySelectorAll('._turnOver'), {css:{display:"block"}});

		this.num = 0;
		this.nextAnimation();

	}
	public nextAnimation(): void
	{
		for(let i=0; i<this.image_turnOver.length; i++)
		{
			gsap.set(this.image_turnOver[i], {css:{display:"none"}});
		}
		gsap.set(this.image_turnOver[this.num], {css:{display:"block"}});

		if(this.num<this.image_turnOver.length-1)
		{
			this.num++;

			setTimeout(this.nextAnimation.bind(this), 30);
		}
		if(this.num==15)
		{
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BOOK_NEXT_COMPLETE));
		}
	}
	public prev(): void
	{
		gsap.set(this.element.querySelectorAll('._open'), {css:{display:"none"}});
		gsap.set(this.element.querySelectorAll('._turnOver'), {css:{display:"block"}});

		this.num = this.image_turnOver.length-10;
		this.prevAnimation();

	}
	public prevAnimation(): void
	{
		for(let i=0; i<this.image_turnOver.length; i++)
		{
			gsap.set(this.image_turnOver[i], {css:{display:"none"}});
		}
		gsap.set(this.image_turnOver[this.num], {css:{display:"block"}});

		if(this.num>0)
		{
			this.num--;

			setTimeout(this.prevAnimation.bind(this), 30);
		}
		if(this.num==0)
		{
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BOOK_PREV_COMPLETE));
		}
	}
	public close(): void
	{
		gsap.set(this.element.querySelectorAll('._open'), {css:{display:"block"}});
		gsap.set(this.element.querySelectorAll('._turnOver'), {css:{display:"none"}});

		this.num = this.image_open.length-10;
		this.closeAnimation();

		gsap.to(this.element, 0.6, {css:{display:"none", opacity:0}, ease:Power1.easeInOut, delay:0.6});

		setTimeout(function()
		{
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.BACK_TO_TOP));
		}, 700);

	}
	public closeAnimation(): void
	{
		for(let i=0; i<this.image_open.length; i++)
		{
			gsap.set(this.image_open[i], {css:{display:"none"}});
		}
		gsap.set(this.image_open[this.num], {css:{display:"block"}});

		if(this.num>0)
		{
			this.num--;

			setTimeout(this.closeAnimation.bind(this), 30);
		}
		else
		{
			// SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_TOP));
		}
	}
	public remove(): void
	{
		gsap.set(this.element, {css:{display:"none", opacity:0}});

	}
}