import { Event } from "../events/EventDispatcher";
import { DataStorage } from "../DataStorage";
import { gsap, Power3 } from "gsap";

export class TypoController
{
	private element: HTMLElement;
	private text: String;
	private num: number;

	constructor(element:HTMLElement, text:String, num:number)
	{
		this.element = element;
		this.text = text;
		this.num = num;

	}
	public add(): void
	{
		const sentence = this.text.split("<br>");

		let newTextContent:string = "";

		newTextContent += "<div class=_num"+this.num+">";
		for(let i=0; i<sentence.length; i++)
		{
			newTextContent += [...sentence[i]].map((char) => `<span>${char}</span>`).join("");
			newTextContent += "<br>";
		}
		newTextContent += "</div>";
		
		this.element.innerHTML += newTextContent;

		gsap.set(this.element.querySelector("._num"+this.num), {css:{display:"none"}});

	}
	public typo(): void
	{
		gsap.set(this.element.querySelector("._num"+this.num), {css:{display:"block"}});

		const _p = Array.from(this.element.querySelector("._num"+this.num).querySelectorAll("span"));

		for(let i=0; i<_p.length; i++)
		{
			gsap.killTweensOf(_p[i]);

			gsap.set(_p[i], {css:{opacity:0, y:DataStorage.window_w/750*20}});
			gsap.to(_p[i], 2.6+Math.random()*1.2, {css:{opacity:0.8, y:0}, ease:Power3.easeOut, delay:Math.random()*0.5});

			// gsap.set(_p[i], {css:{opacity:0}});
			// gsap.to(_p[i], 1.5, {css:{opacity:0.8}, ease:Power1.easeInOut, delay:Math.random()*0.9+0.6});

			// gsap.set(_p[i], {css:{opacity:0, x:26}});
			// gsap.to(_p[i], 3.6, {css:{opacity:0.8, x:0}, ease:Power3.easeOut, delay:i*0.01+0.3});
		}
	}
	public hide(): void
	{
		gsap.set(this.element.querySelector("._num"+this.num), {css:{display:"none"}});

	}
}