import { DataStorage } from "../DataStorage";
import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { gsap, Power1, Power3 } from "gsap";

export class OpeningView
{
	private element: HTMLElement;
	
	private logo: HTMLElement;
	private text: HTMLElement;

	private p: HTMLElement[];

	private addBindThis: Function;
	private removeBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#opening");
		
		this.logo = this.element.querySelector("._logo");
		this.text = this.element.querySelector("._text");

		const sentence = this.text.innerHTML.split("<br>");

		let newTextContent:string = "";

		for(let i=0; i<sentence.length; i++)
		{
			newTextContent += [...sentence[i]].map((char) => `<span>${char}</span>`).join("");
			newTextContent += "<br>";
		}
		
		this.text.innerHTML = newTextContent;
		
		this.p = Array.from(this.text.querySelectorAll("span"));

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_OPENING, this.addBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_OPENING, this.removeBindThis);

	}
	public add(): void
	{
		gsap.set(this.element, {css:{display:"block"}});

		this.intro();

		gsap.delayedCall(1.8, this.typo.bind(this));
		gsap.delayedCall(5.1, this.outro.bind(this));

	}
	public intro(): void
	{
		gsap.to(this.logo, 0.6, {css:{opacity:1}, ease:Power1.easeInOut});
		gsap.to(this.logo, 1.5, {css:{marginTop:-DataStorage.window_w/750*360}, ease:Power3.easeInOut, delay:0.6});

	}
	public typo(): void
	{
		gsap.set(this.text, {css:{display:"block"}});

		for(let i=0; i<this.p.length; i++)
		{
			gsap.set(this.p[i], {css:{opacity:0, y:DataStorage.window_w/750*20}});
			gsap.to(this.p[i], 0.9, {css:{opacity:1, y:0}, ease:Power3.easeOut, delay:i*0.006});
		}
	}
	public outro(): void
	{
		gsap.to(this.element, 1.2, {css:{opacity:0}, ease:Power1.easeInOut, onComplete:this.introComplete.bind(this)});

	}
	public introComplete(): void
	{
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_OPENING));
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_TOP));
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_HEADER));

	}
	public remove(): void
	{
		gsap.set(this.element, {css:{display:"none"}});

	}
}