import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { DataStorage } from '../DataStorage';
import { APIController } from '../controller/APIController';
import { ListSliderController } from '../controller/ListSliderController';
import { gsap, Power1, Power3, Power4 } from "gsap";

export class TopView
{
	private element: HTMLElement;
	
	private container: HTMLElement;
	private slider: HTMLElement;
	private choice: HTMLElement;

	private addBindThis: Function;
	private removeBindThis: Function;
	private backBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#top");

		this.container = this.element.querySelector("._container");
		this.slider = this.element.querySelector("._slider");

		new ListSliderController(this.element.querySelector("._slider"), this.element.querySelector("._list"));

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_TOP, this.addBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_TOP, this.removeBindThis);

		this.backBindThis = this.back.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.BACK_TO_TOP, this.backBindThis);

	}
	public add(): void
	{
		var _tag = "";

		_tag += '<img class="_shadow" src="/img/top/shadow.png" alt="">';

		if(DataStorage.yourhistoryEpisode.length>0)
		{
			_tag += '<div class="_yourStory"><img src="/img/top/yourStory.png" alt="YOUR STORY WITH CARTIER"></div>';
		}
		else
		{
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_SEND_YOUR_HISTORY_MODAL));
		}

		for(let i=0; i<DataStorage.episode.episodes.length; i++)
		{
			if(DataStorage.episode.episodes[i].episodecnt>=500)
			{
				_tag += '<div class="_usersStory" data-id="'+DataStorage.episode.episodes[i].episodeId+'" data-no="'+(100-i)+'">';
				_tag += '<img src="/img/top/usersStory_0.png" alt="USER’S STORY No.">';
				_tag += this.number(100-i);
				_tag += '</div>';
			}
			else if(DataStorage.episode.episodes[i].episodecnt>=400)
			{
				_tag += '<div class="_usersStory" data-id="'+DataStorage.episode.episodes[i].episodeId+'" data-no="'+(100-i)+'">';
				_tag += '<img src="/img/top/usersStory_1.png" alt="USER’S STORY No.">';
				_tag += this.number(100-i);
				_tag += '</div>';
			}
			else if(DataStorage.episode.episodes[i].episodecnt>=300)
			{
				_tag += '<div class="_usersStory" data-id="'+DataStorage.episode.episodes[i].episodeId+'" data-no="'+(100-i)+'">';
				_tag += '<img src="/img/top/usersStory_2.png" alt="USER’S STORY No.">';
				_tag += this.number(100-i);
				_tag += '</div>';
			}
			else
			{
				_tag += '<div class="_usersStory" data-id="'+DataStorage.episode.episodes[i].episodeId+'" data-no="'+(100-i)+'">';
				_tag += '<img src="/img/top/usersStory_3.png" alt="USER’S STORY No.">';
				_tag += this.number(100-i);
				_tag += '</div>';
			}
		}
		this.container.innerHTML = _tag;
		
		gsap.to(this.element, 0.9, {css:{display:"block", opacity:1}, ease:Power1.easeInOut});
		
		gsap.to(this.container, 1.5, {css:{opacity:1, left:0}, ease:Power4.easeOut});
		gsap.to(this.slider, 0.3, {css:{opacity:1}, ease:Power1.easeInOut, delay:1.2});

		if(this.container.querySelector('._yourStory'))
		{
			this.container.querySelector('._yourStory').addEventListener('click', (event) =>
			{
				this.choice = this.container.querySelector('._yourStory') as HTMLElement;
				gsap.to(this.choice, 1.2, {css:{scale:1.1, y:-DataStorage.window_w/750*10, zIndex:100}, ease:Power3.easeOut});

				DataStorage.episodeNo = DataStorage.yourhistoryEpisode.length;

				gsap.to(this.element, 0.9, {css:{opacity:0}, ease:Power1.easeIn, delay:0.3, onComplete:this.yourStory.bind(this)});

				setTimeout(function()
				{
					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_BOOK));
				},260);
			});
		}

		this.container.querySelectorAll('._usersStory').forEach((element) =>
		{
			element.addEventListener('click', (event) =>
			{
				this.choice = element as HTMLElement;
				gsap.to(this.choice, 1.2, {css:{scale:1.1, y:-DataStorage.window_w/750*10, zIndex:100}, ease:Power3.easeOut});

				DataStorage.episodeId = Number(this.choice.dataset.id);
				DataStorage.episodeNo = Number(this.choice.dataset.no);

				gsap.to(this.element, 0.6, {css:{opacity:0}, ease:Power1.easeIn, delay:0.3, onComplete:this.usersStory.bind(this)});

				setTimeout(function()
				{
					SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_BOOK));
				},260);
			});
		});
	}
	public number(_num:number): string
	{
		const _numStr = _num.toString().split("");
		var _tag = "";
		_tag += '<div class="_number">';
		for(let i=0; i<_numStr.length; i++)
		{
			_tag += '<img src="/img/top/'+_numStr[i]+'.png" alt="1">';
		}
		_tag += '</div>';

		return _tag;

	}
	public yourStory(): void
	{
		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_TOP));

		DataStorage.usersstoryEpisode = DataStorage.yourhistoryEpisode[0];
		DataStorage.hasEpisode = true;

		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.ADD_EPISODE));

	}
	public usersStory(): void
	{
		APIController.getEpisodeByEpisodeId(DataStorage.episodeId);

		SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_TOP));
	}
	public remove(): void
	{
		gsap.set(this.element, {css:{opacity:0}});

	}
	public back(): void
	{
		gsap.to(this.element, 0.9, {css:{opacity:1}, ease:Power1.easeInOut});

		gsap.to(this.choice, 1.2, {css:{scale:1, y:0, zIndex:0}, ease:Power3.easeOut, delay:0.3});

	}
}