import { Event } from "../events/EventDispatcher";
import { SceneEvent } from "../SceneEvent";
import { gsap, Power1 } from "gsap";

export class SendYourhistoryModal
{
	private element: HTMLElement;

	private addBindThis: Function;
	private removeBindThis: Function;

	constructor()
	{
		this.element = document.querySelector("#sendYourhistoryModal");
		
		this.element.querySelector('._close').addEventListener('click', (event) =>
		{
			SceneEvent.getInstance().dispatchEvent(new Event(SceneEvent.REMOVE_SEND_YOUR_HISTORY_MODAL));
		});

		this.addBindThis = this.add.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.ADD_SEND_YOUR_HISTORY_MODAL, this.addBindThis);

		this.removeBindThis = this.remove.bind(this);
		SceneEvent.getInstance().addEventListener(SceneEvent.REMOVE_SEND_YOUR_HISTORY_MODAL, this.removeBindThis);

	}
	public add(): void
	{
		gsap.to(this.element, 0.6, {css:{display:"block", opacity:1}, ease:Power1.easeInOut});

	}
	public remove(): void
	{
		gsap.to(this.element, 0.3, {css:{display:"none", opacity:0}, ease:Power1.easeInOut});

	}
}